import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from '../../util/reactIntl';
import { fetchReviews, wishlistlisting } from '../../containers/ListingPage/ListingPage.duck';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StarIcon from '../../assets/Icons/rate.svg';
import heartFilled from '../../assets/Icons/heartFilled.svg';
import msg from '../../assets/Icons/msg.svg';
import address from '../../assets/Icons/address.svg';
import { NamedLink, ResponsiveImage } from '../../components';
import CardImg from '../../assets/card-img.png';
import { createSlug } from '../../util/urlHelpers';
import css from './SingleListCard.module.css';
import Heart from '../Heart/Heart';
import { Avatar } from '@material-ui/core';
const useStyles = makeStyles({
  root: {
    borderRadius: '8px',
    maxWidth: 345,
  },
});

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const SingleListCard = props => {
  const { userDetails, fetchReviewsFunc, getSortAllData, listing } = props;
  const [state, setState] = useState([]);
  const [totalAvrage, setTotalAvrage] = useState(null);
  const [totalReview, setTotalReview] = useState(0);
  const classes = useStyles();
  const slug = createSlug(userDetails.userName);
  const id = userDetails.id;

  useEffect(() => {
    fetchReviewsFunc(id, 'listpage')
      .then(response => {
        setState(response);
        let rating1 = 0;
        let rating2 = 0;
        let rating3 = 0;
        let rating4 = 0;
        let rating5 = 0;
        response?.map(values => {
          switch (values?.attributes?.rating) {
            case 1:
              rating1 += 1;
              break;
            case 2:
              rating2 += 1;
              break;
            case 3:
              rating3 += 1;
              break;
            case 4:
              rating4 += 1;
              break;
            case 5:
              rating5 += 1;
              break;
          }
        });
        let totalReview = rating5 + rating4 + rating3 + rating2 + rating1;

        setTotalReview(totalReview);
        let totalAvrage =
          (5 * rating5 + 4 * rating4 + 3 * rating3 + 2 * rating2 + 1 * rating1) / totalReview;
        if (totalAvrage === 0 || totalAvrage) {
          console.log(totalAvrage);
          setTotalAvrage(parseFloat(totalAvrage).toFixed(1));
        }
      })
      .catch(err => console.log(err));
  }, [id]);

  return (
    <Card className={classes.root + ' ' + 'singleCardMAxWidth cardContainerSingle'}>
      <a
        href={`/l/${slug}/${id}`}
        className={classes}
        // name="ListingPage"
        // params={{ id, slug }}
        style={{ color: '#475467', textDecoration: 'none' }}
      >
        <CardActionArea>
          <div className={css.MediaContainer}>
            {userDetails.userImage ? (
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                height="200"
                image={userDetails.userImage}
                title="Contemplative Reptile"
              />
            ) : (
              <Avatar style={{ height: '200px', width: '100%', borderRadius: 0 }}>{userDetails?.displayName}</Avatar>
            )}
            {/* {props.isFav && <img src={heartFilled} alt="liked" class={css.liked} />} */}
            <span className={css.liked}>
              <Heart isFilled={props.isFav} />
            </span>
          </div>
          <CardContent>
            <div className={css.cardHeader}>
              <Typography gutterBottom variant="h5" component="h2" style={{ marginBottom: 0 }}>
                {userDetails?.userName}
              </Typography>
              {totalAvrage > 0 && (
                <span style={{ display: 'flex', gap: '5px' }}>
                  {totalAvrage}
                  <img src={StarIcon} alt="star" />
                </span>
              )}
            </div>
            {userDetails?.city && (
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ wordWrap: 'break-word', marginBottom: '5px' }}
              >
                {props.searchParams.keywords ? (
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: '5px' }}
                  >
                    <rect width="24" height="24" rx="12" fill="#D1FADF" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.0964 7.39004L9.93638 14.3L8.03638 12.27C7.68638 11.94 7.13638 11.92 6.73638 12.2C6.34638 12.49 6.23638 13 6.47638 13.41L8.72638 17.07C8.94638 17.41 9.32638 17.62 9.75638 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                      fill="#12B76A"
                    />
                  </svg>
                ) : (
                  <img src={address} alt="address" class={css.cardIcons} />
                )}
                {userDetails?.city}
                {/* Coral Springs, FL, United States */}
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary" component="p">
              <img src={msg} alt="msg" class={css.cardIcons} />
              Responds within: {userDetails?.resTime} hours
            </Typography>

            <div className={css.cardTags}>
              {userDetails?.musicStyles.map((musicName, key) => (
                <span class={css.tag} key={key}>
                  {capitalizeFirstLetter(musicName)
                    .replaceAll('_', ' ')
                    .replaceAll('christianc', 'christian')}
                </span>
              ))}
              {/* <span class={css.tag}>Gospel</span>
            <span class={css.tag}>Charismatic</span>
            <span class={css.tag}>Contemporary Christian Music</span> */}
            </div>

            <div className={css.cardHeader}>
              <Typography
                gutterBottom
                variant="h6"
                component="h2"
                style={{ marginBottom: 0, fontSize: '16px' }}
              >
                ${userDetails?.perHourRate} per hour
              </Typography>
              <span>
                <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
                  <a href="#" style={{ fontSize: '14px', textDecoration: 'underline' }}>
                    View listing
                  </a>
                </NamedLink>
              </span>
            </div>
          </CardContent>
        </CardActionArea>
      </a>
    </Card>
  );
};

const mapStateToProps = state => {
  const { reviews, fetchReviewsError } = state.ListingPage;
  // const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;

  return { reviews, fetchReviewsError, isAuthenticated };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchReviewsFunc: (id, section) => dispatch(fetchReviews(id, section)),
  // onWishListListing: listingId => dispatch(wishlistlisting(listingId)),
  // authenticationFunc: () => dispatch(authInfo()),
  // fetchCurrent: () => dispatch(fetchCurrentUser()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(SingleListCard);
