import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form } from '../../components';

import css from './EditListingFeaturesForm.module.css';

function removeSpecialCharacters(inputString) {
  // Define a regular expression pattern to match special characters
  const regex = /[^a-zA-Z0-9\s]/g;

  // Use the replace method to remove the special characters
  const cleanedString = inputString.replace(regex, '');

  return cleanedString;
}

const EditListingFeaturesFormComponent = props => {
  const [priceValue, SetPriceValue] = useState(props.servicePriceD);

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      onSubmit={e => {
        e['servicePrice'] = priceValue;
        console.log('priceValue submit', priceValue);
        props.onSubmit(e);
      }}
      render={formRenderProps => {
        const {
          disabled,
          ready,
          rootClassName,
          className,
          name,
          handleSubmit,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          filterConfig,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = disabled || submitInProgress;

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
          </p>
        ) : null;

        const options = findOptionsForSelectFilter('yogaStyles', filterConfig);
        // console.log('options', props);
        const priceOptions = props.servicePriceD;
        let suggestedPerHour = {
          worship_leader: '$50-$100',
          drummer: '$40-$75',
          bassist: '$40-$75',
          pianist: '$40-$75',
          acoustic_guitar: '$40-$75',
          electric_guitar: '$40-$75',
          vocalist: '$40-$75',
          tech: '$40-$75',
          special_events: '$40-$75',
          organist: '$40-$75',
        };
        let isNotSelected = true;
        if (!Array.isArray(priceValue) && Object.keys(priceValue || {}).length > 0) {
          isNotSelected = false;
        }

        // console.log('priceValue', priceOptions);
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
            {errorMessageShowListing}
            <h2 style={{ marginLeft: 'auto', marginRight: '15px' }}>Per Hour</h2>
            <FieldCheckboxGroup
              className={css.features}
              id={name}
              name={name}
              options={options}
              selected_value={props?.initialValues?.yogaStyles}
              html={val => {
                const isRequired =
                  props?.initialValues?.yogaStyles?.indexOf(val) > -1 ? true : false;
                const checkingPrice = priceValue ? priceValue[val] : '';
                // console.log('priceValue', { props, priceValue, val });
                return (
                  <div style={{ display: 'flex', gap: '30px' }}>
                    <p>Suggested per hour in your area {suggestedPerHour[val]}</p>
                    <input
                      className={css.placeholderTxt}
                      type="text"
                      name={`${val}`}
                      // placeholder={priceOptions[val] ? priceOptions[val] : ''}
                      required={isRequired}
                      value={checkingPrice}
                      onChange={evt => {
                        SetPriceValue({
                          ...priceValue,
                          [evt.target.name]: removeSpecialCharacters(evt.target.value),
                        });
                        console.log('e.target.value', {
                          priceValue,
                          [evt.target.name]: removeSpecialCharacters(evt.target.value),
                        });
                      }}
                    />
                  </div>
                );
              }}
            />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled || isNotSelected}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
