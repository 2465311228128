import React from 'react';
import SingleListCard from '../../components/SingleListCard/SingleListCard';
import css from './SingleListCardContainer.module.css';

function convertH2M(timeInHour) {
  let timeParts = timeInHour.split(':');
  return Number(timeParts[0]) * 60 + Number(timeParts[1]);
}

const convertIntoMoney = amount => {
  return parseInt(amount / 100);
};

const convertMinsToHrsMins = n => {
  let num = n;
  let hours = num / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  // return num + ' minutes = ' + rhours + ' hour(s) and ' + rminutes + ' minute(s).';
  return rhours;
};

const SingleListCardContainer = props => {
  const { listings, getSortAllData } = props;
  let available_list = 0;
  console.log('filtered.listings',listings)
  const singleCard = listings?.map((listing, ind) => {
    const attr = listing?.attributes || {};
    const userDetails = {};
    const isFav = listing.isFavorite;

    userDetails['perHourRate'] = convertIntoMoney(attr?.price?.amount);
    userDetails['userName'] = attr?.title;
    userDetails['id'] = listing?.id?.uuid;
    userDetails['musicStyles'] = attr?.publicData?.musicStyles || [];

    let timeInMinutesArray = attr?.publicData?.averageTime?.map(data => {
      return convertH2M(data);
    });

    let sumofminitus = timeInMinutesArray?.reduce(function(acc, val) {
      return acc + val;
    }, 0);

    let avarageMinitus = sumofminitus / timeInMinutesArray?.length;
    const rand = Math.floor(Math.random() * 11);

    let avarageMinitusTime = rand;
    if (attr?.publicData?.averageTime !== undefined && attr?.publicData.averageTime?.length > 0) {
      avarageMinitusTime = convertMinsToHrsMins(avarageMinitus);
    }
    const userImage =
      listing.images && listing.images.length > 0
        ? listing.images[0]?.attributes?.variants['landscape-crop2x']?.url
        : null;

    userDetails['resTime'] = avarageMinitusTime ? avarageMinitusTime : '00:00';
    userDetails['city'] = attr?.publicData?.city;
    const userProfileImage = listing?.author?.profileImage?.attributes?.variants['landscape-crop2x'].url;
    // userDetails['userImage'] = userImage;
    userDetails['userImage'] = userProfileImage;
    userDetails['displayName'] = listing?.author?.attributes?.profile?.abbreviatedName;
    console.log('userProfileImage',userProfileImage)
    return (
      <SingleListCard
        searchParams={props.searchParams}
        listing={listings}
        userDetails={userDetails}
        getSortAllData={getSortAllData}
        isFav={isFav}
      />
    );
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '70px' }}>
      <div className={css.SingleCardAdjustment + ' singleCardContainer'}>{singleCard}</div>

      <a
        className="viewAllLink"
        href="#"
        style={{ marginLeft: 'auto', textDecoration: 'underline' }}
      >
        View all
      </a>
    </div>
  );
};

export default SingleListCardContainer;
